import Form from "./Form";
import cn from "classnames";
import styles from "./Home.module.css";

const Home = ({ utmCampaign, utmSource, utmMedium, salesPersonId }) => {
  return (
    <main className={cn(styles["home"])}>
      <h1 className={cn(styles["home__heading"])}>
        LearnHat Student Registration Form
      </h1>
      <section className={cn(styles["home__form-container"])}>
        <Form
          utmCampaign={utmCampaign}
          utmMedium={utmMedium}
          utmSource={utmSource}
          salesPersonId={salesPersonId}
        />
      </section>
    </main>
  );
};

export default Home;
