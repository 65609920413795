export function validateName(name) {
  if (name !== "") {
    let res = /^[a-zA-Z ]+$/.test(name);
    if (res) {
      return "";
    } else {
      return "Use only letters for name";
    }
  } else {
    return "Name is required";
  }
}


function checkPhoneNoFormat(phoneNo) {
  if(phoneNo.length >= 10){
    const res = /^[0-9]+$/.test(phoneNo);
    return res;
  }else return false;
}

export function validatePhoneNo(phoneNo) {
  if (phoneNo !== "") {
    if (checkPhoneNoFormat(phoneNo)) {
      return "";
    } else {
      return "Enter a valid phone number";
    }
  } else {
    return "Phone number is required";
  }
}

export function validateClass(classStudying){
  if(classStudying !== ""){
    return ""
  }else{ 
    return "Class is required"
  }
}

export function validateSubject(subject) {
  if (subject !== "") {
    return "";
  } else {
    return "Select a subject";
  }
}
