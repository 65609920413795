import cn from "classnames";
import styles from "./Input.module.css"

const InputText = ({
  placeholder,
  onChange,
  required,
  className,
  id,
  name,
  autoComplete,
  maxLength,
  value,
  onBlur
}) => {
  return (
    <input
      required={required}
      className={cn(styles.input, className)}
      type="text"
      placeholder={placeholder && placeholder}
      onChange={onChange}
      id={id}
      name={name}
      maxLength={maxLength}
      autoComplete={autoComplete}
      value={value}
      onBlur={onBlur}
    />
  );
};

const InputSelect = ({
  children,
  onChange,
  id,
  name,
  className,
  required,
  value,
  onBlur,
}) => {
  return (
    <select
      onChange={onChange}
      className={cn(styles.input, styles["input-select"], className)}
      id={id}
      name={name}
      required={required}
      value={value}
      onBlur={onBlur}
    >
      {children}
    </select>
  );
};

export { InputText, InputSelect };
