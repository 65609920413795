const Navbar = () => {
  return (
    <div className={"navbar"}>
      <a href="https://www.learnhat.com" target="_blank" rel="noreferrer">
        <img
          src="https://res.cloudinary.com/learnhat/image/upload/v1628258183/website_images/logo_learnhat.png"
          alt="learnhat-logo"
        />
      </a>
    </div>
  );
};

export default Navbar;
