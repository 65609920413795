/* eslint-disable */

import { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Home.module.css";
import { InputText, InputSelect } from "../utils/input";
import { PurpleButton } from "../utils/buttons";
import {
  validateName,
  validatePhoneNo,
  validateClass,
  validateSubject,
} from "../utils/validations";
import { useToast } from "@chakra-ui/react";
import { useLoading } from "../utils/loader";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Form = ({ utmCampaign, utmSource, utmMedium, salesPersonId }) => {
  const [name, setName] = useState("");
  const [mobNo, setMobNo] = useState("");
  const [grades, setGrades] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [category, setCategory] = useState("k12");
  const [selectedSubject, setSelectedSubject] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [termsAgreed, setTermsAgreed] = useState(true);

  const [nameValidationMsg, setNameValidationMsg] = useState("");
  const [phoneValidationMsg, setPhoneValidationMsg] = useState("");
  const [classValidationMsg, setClassValidationMsg] = useState("");
  const [subjectValidationMsg, setSubjectsValidationMsg] = useState("");

  useEffect(() => {
    getGradeList();
  }, []);

  const getGradeList = async () => {
    try {
      const { status, data } = await axios.get(
        `https://api.learnhat.com/Learnhatapi/gradeList`
      );
      if (status === 200) {
        setGrades(data.grades.slice(1, 13));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [salesPersonName, setSalesPersonName] = useState("");
  const source_adv = `B2C - ${salesPersonName}(${utmMedium})`;

  const toast = useToast();
  const { setLoadingStatus } = useLoading();
  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      try {
        const { status, data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/sales-person/${salesPersonId}/detail`
        );
        if (status === 200) {
          setSalesPersonName(data.salesPerson.name);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  function handleNameInput(e) {
    setName(e.target.value);
  }

  function handleMobNoInput(e) {
    setMobNo(e.target.value);
  }

  const handleGradeSelection = (e) => {
    setSelectedGrade(e.target.value.split(","));
    getSubjectsData(e.target.value.split(",")[1]);
  };

  const handleSubjectSelection = (e) => {
    setSelectedSubject(e.target.value.split(","));
  };

  const getSubjectsData = async (grade) => {
    try {
      const { status, data } = await axios.get(
        `https://api.learnhat.com/Learnhatapi/getSubjects/${grade}`
      );
      if (status === 200) {
        setSubjects(data.subjects);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function handleAgreedTerms(e) {
    e.target.checked ? setTermsAgreed(true) : setTermsAgreed(false);
  }

  function checkName() {
    setNameValidationMsg(validateName(name));
  }

  function checkPhoneNo() {
    setPhoneValidationMsg(validatePhoneNo(mobNo));
  }

  function checkClass() {
    setClassValidationMsg(validateClass(selectedGrade[0]));
  }

  function checkSubject() {
    setSubjectsValidationMsg(validateSubject(selectedSubject[0]));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    checkName();
    checkPhoneNo();
    checkClass();
    checkSubject();
    if (
      name &&
      mobNo &&
      selectedGrade.length > 0 &&
      selectedSubject.length > 0 &&
      nameValidationMsg === "" &&
      phoneValidationMsg === "" &&
      classValidationMsg === "" &&
      subjectValidationMsg === ""
    ) {
      setLoadingStatus("loading");
      submitData();
    }
  }

  function submitData() {
    (async () => {
      try {
        const { status } = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/student/signup`,
          {
            student: {
              name,
              mobileNumber: mobNo,
              class: selectedGrade[1],
              subjects: selectedSubject[1],
              source: utmSource,
              medium: utmMedium,
              campaign: utmCampaign,
              agreedToTerms: termsAgreed,
              salesPersonId,
            },
            salesPersonId: salesPersonId,
            source_adv,
          }
        );
        if (status === 200) {
          setLoadingStatus("done");
          navigate("/thankyou");
        }
      } catch (error) {
        console.log(error);
        setLoadingStatus("done");
        toast({
          title: "Something went wrong!!",
          status: "error",
          duration: 1000,
          isClosable: true,
        });
      }
    })();
  }

  function preventSubmit(e) {
    e.preventDefault();
  }

  function handleAdultLearnerClick() {
    setCategory("adult");
    setSelectedGrade(() => ["16", "Misc"]);
    getSubjectsData("16");
  }

  function handleK12Click() {
    setCategory("k12");
    setSelectedGrade(() => []);
    setSelectedSubject(() => []);
  }

  return (
    <form
      className={cn(styles["home__form"])}
      method="post"
      encType="multipart/form-data"
      onSubmit={preventSubmit}
    >
      <div className={cn(styles["home__categories"])}>
        <PurpleButton
          className={category !== "k12" && "btn-transparent--purple"}
          onClick={handleK12Click}
        >
          Class 1-12
        </PurpleButton>
        <PurpleButton
          className={category !== "adult" && "btn-transparent--purple"}
          onClick={handleAdultLearnerClick}
        >
          Adult Learner
        </PurpleButton>
      </div>
      <InputText
        type="text"
        id="FirstName"
        name="FirstName"
        maxlength="100"
        autocomplete="off"
        placeholder="Name"
        className="mt-05"
        value={name}
        onChange={handleNameInput}
        onBlur={checkName}
      />
      {nameValidationMsg && (
        <p className="validation-message">{nameValidationMsg}</p>
      )}
      <InputText
        type="text"
        id="Phone"
        name="Phone"
        maxlength="50"
        autocomplete="off"
        placeholder="Mobile number"
        className="mt-05"
        value={mobNo}
        onChange={handleMobNoInput}
        onBlur={checkPhoneNo}
      />
      {phoneValidationMsg && (
        <p className="validation-message">{phoneValidationMsg}</p>
      )}
      {category === "k12" && (
        <>
          <InputSelect
            onChange={(e) => handleGradeSelection(e)}
            className="mt-05"
          >
            <option value="" selected hidden onBlur={checkClass}>
              Select Grade
            </option>
            {grades.map(({ class_id, class_name }) => {
              return (
                <option key={class_id} value={[class_id, class_name]}>
                  {class_name}
                </option>
              );
            })}
          </InputSelect>
          {classValidationMsg && (
            <p className="validation-message">{classValidationMsg}</p>
          )}
        </>
      )}
      {selectedGrade.length > 0 && (
        <InputSelect
          onChange={handleSubjectSelection}
          className="mt-05"
          onBlur={checkSubject}
        >
          <option value="" selected hidden>
            Select Subject
          </option>
          {subjects.map(({ subject_id, subject_name }) => (
            <option key={subject_id} value={[subject_id, subject_name]}>
              {subject_name}
            </option>
          ))}
        </InputSelect>
      )}
      {subjectValidationMsg && (
        <p className="validation-message">{subjectValidationMsg}</p>
      )}
      <span className={cn(styles["tc"])}>
        <span className={cn(styles["tc-input"])}>
          <input
            type="checkbox"
            id="MailingPreferences"
            name="MailingPreferences"
            checked={termsAgreed}
            onChange={handleAgreedTerms}
            className="mr-05"
          />
          <label htmlFor="MailingPreferences">
            I agree to LearnHat's{" "}
            <a
              href="https://learnhat.com/terms"
              target="_blank"
              rel="noreferrer"
              className="color--blue"
            >
              Terms and Conditions
            </a>{" "}
            and{" "}
            <a
              href="https://learnhat.com/privacy"
              target="_blank"
              rel="noreferrer"
              className="color--blue"
            >
              Privacy Policy
            </a>{" "}
            of LearnHat.
          </label>
        </span>
      </span>
      <br />
      <PurpleButton
        id="form-submit-button"
        type="submit"
        onClick={handleSubmit}
      >
        Submit
      </PurpleButton>
      <br />
    </form>
  );
};

export default Form;
