import cn from "classnames";
const styles = require("./Buttons.module.css");

const BlueButton = ({ children, className }) => {
  return (
    <button className={cn(styles.btn, styles["btn--blue"], className)}>
      {children}
    </button>
  );
};

const PurpleButton = ({
  btnType,
  children,
  className,
  id,
  type,
  onClick
}) => {
  return (
    <button
      type={type}
      id={id}
      className={cn(
        styles.btn,
        styles["btn-purple"],
        styles[btnType],
        styles[className]
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const IconButton = ({
  children,
  className,
  onClick,
}) => {
  return (
    <button className={cn(styles.btnicon, className)} onClick={onClick}>
      {children}
    </button>
  );
};

const IconWithTextButton = ({
  children,
  onClick,
  onHover,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      onMouseOver={onHover}
      onMouseOut={onHover}
      className={cn(styles["btn-iconwithtext"], className)}
    >
      {children}
    </button>
  );
};

export { BlueButton, PurpleButton, IconButton, IconWithTextButton };
