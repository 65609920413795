import cn from "classnames";
import styles from "./Home.module.css";
import { Helmet } from "react-helmet";

const Thankyou = () => {
  return (
    <div className={cn(styles["thankyou"])}>
      <Helmet>
        <script>
          {`window.gtag('event', 'conversion', {'send_to': 'AW-624616926/y2-qCNyAgfECEN7L66kC'})`}
        </script>
      </Helmet>
      <h1 className={cn(styles["thankyou__message"])}>
        Thank you for submitting your application.
      </h1>
      <h2 className={cn(styles["thankyou__info"])}>
        Our team will get in touch with you.
      </h2>
    </div>
  );
};

export default Thankyou;
