import { useLoading } from "."
import { Spinner } from "@chakra-ui/react"

const FullScreenLoader = () => {

    const { loadingStatus } = useLoading()

    return (
      <div
        className={
          loadingStatus === "loading" ? "loading-overlay overlay-active" : "loading-overlay"
        }
      >
          <Spinner
            thickness="4px"
            speed="0.65s"
            color="purple.500"
            size="xl"
          />
      </div>
    );
}

export default FullScreenLoader
