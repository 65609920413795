import { useState, useContext, createContext } from "react"

const LoadingContext = createContext()

export const LoadingProvider = ({ children }) => {

    const [loadingStatus, setLoadingStatus] = useState("done")

    return <LoadingContext.Provider value={{ loadingStatus, setLoadingStatus }}>
        {children}
    </LoadingContext.Provider>
}

export const useLoading = () => useContext(LoadingContext)